import { ref, computed } from '@vue/composition-api'

import { useStoreModule } from "@/composition/useStoreModule";
import { driversStoreModule } from "@/store/businessUsers/driversStore.module"
import { useDrivers } from "@/composition/businessUsers/useDrivers";

const STORE_MODULE_NAME = 'businessUsers';

export default function useDriversList() {

    useStoreModule(STORE_MODULE_NAME, driversStoreModule);
    const { fetchDrivers, driversFiltered, drivers } = useDrivers();

    const refUserListTable = ref(null)

    // Table Handlers
    const tableColumns = [
        { key: 'driver', label: "Водій", sortable: false, mobile: true, tdClass: 'custom-media-cell' },
        // { key: 'isAllowSelfPay', label: "Поповнення", sortable: false },
        { key: 'phone', label: "Номер телефону", sortable: false },
        { key: 'balanceType', label: "Тип рахунку", sortable: false },
        { key: 'groups', label: "Групи", sortable: false },
        { key: 'cards', label: "Карти", sortable: false },
        { key: 'status', label: "Статус", sortable: false, mobile: true, tdClass: 'custom-media-cell' },
        { key: 'actions', label: "Дії", sortable: false, mobile: true, tdClass: 'custom-media-cell' },
    ]
    const perPage = ref(10)
    const currentPage = ref(1)
    const perPageOptions = [10, 25, 50, 100]
    const searchQuery = ref('')
    const sortBy = ref('id')
    const isSortDirDesc = ref(true)
    const balanceFilter = ref(null)
    const groupFilter = ref(null)
    const statusFilter = ref(null)

    const totalDrivers = computed(() => {
        return drivers.value.length
    })

    const refetchData = () => {
        fetchDrivers({},false);
    }

    fetchDrivers();

    // *===============================================---*
    // *--------- UI ---------------------------------------*
    // *===============================================---*

    const resolveUserBalanceVariant = balance => {
        if (balance == '1') return 'warning'
        if (balance == '2') return 'info'
        return 'primary'
    }

    const resolveUserBalance = balance => {
        if (balance == '1') return 'Баланс'
        if (balance == '2') return 'Ліміт'
    }

    const resolveUserStatus = status => {
        if (status === 'WAITING_FOR_USER_REGISTRATION') return 'Очікується реєстрація користувача'
        if (status === 'ACCOUNT_PROCESSING') return 'Обробка'
        if (status === 'WAITING_DRIVER_PIN_CONFIRMATION') return 'Очікується підтвердження PIN-коду'
        if (status === 'CONFIRMED') return 'Підтверджено'
        if (status === 'PENDING_DATEX_CREATION') return 'Очікується створення DATEX'
        if (status === 'PREPARED') return 'Готовий'
        if (status === 'ACTIVE') return 'Активний'
        if (status === 'BLOCKED') return 'Заблокований'
        return 'secondary'
    }

    const resolveUserStatusVariant = status => {
        if (status === 'WAITING_FOR_USER_REGISTRATION') return 'secondary'
        if (status === 'ACCOUNT_PROCESSING') return 'secondary'
        if (status === 'WAITING_DRIVER_PIN_CONFIRMATION') return 'secondary'
        if (status === 'CONFIRMED') return 'primary'
        if (status === 'PENDING_DATEX_CREATION') return 'secondary'
        if (status === 'PREPARED') return 'info'
        if (status === 'ACTIVE') return 'success'
        if (status === 'BLOCKED') return 'danger'
        return 'secondary'
    }

    return {
        driversFiltered,
        tableColumns,
        perPage,
        currentPage,
        totalDrivers,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refUserListTable,

        resolveUserBalanceVariant,
        resolveUserBalance,
        resolveUserStatusVariant,
        refetchData,
        resolveUserStatus,

        // Extra Filters
        balanceFilter,
        groupFilter,
        statusFilter,
    }
}