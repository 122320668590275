<template>
  <div>

    <!-- Media -->
    <b-media class="mb-2 align-items-center">
      <template #aside>
        <b-avatar
          ref="previewEl"
          :src="driverData.avatar"
          :text="avatarText(driverData.fullName)"
          :variant="`light-${resolveUserBalanceVariant(driverData.role)}`"
          size="90px"
          rounded
        />
      </template>
      <h4 class="mb-0">
        {{ driverData.fullName }}
      </h4>
      <!-- <div class="d-flex flex-wrap">
        <b-button
          variant="primary"
          @click="$refs.refInputEl.click()"
        >
          <input
            ref="refInputEl"
            type="file"
            class="d-none"
            @input="inputImageRenderer"
          >
          <span class="d-none d-sm-inline">Update</span>
          <feather-icon
            icon="EditIcon"
            class="d-inline d-sm-none"
          />
        </b-button>
        <b-button
          variant="outline-secondary"
          class="ml-1"
        >
          <span class="d-none d-sm-inline">Remove</span>
          <feather-icon
            icon="TrashIcon"
            class="d-inline d-sm-none"
          />
        </b-button>
      </div> -->
    </b-media>

    <!-- Driver Info: Input Fields -->
    <b-form>
      <b-row>

        <!-- Field: Full Name -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Повне імʼя"
            label-for="full-name"
          >
            <b-form-input
              id="full-name"
              v-model="driverData.fullName"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Email -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Номер телефону"
            label-for="phone"
          >
            <b-form-input
              id="phone"
              v-model="driverData.phone"
              readonly
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Код підтвердження"
            label-for="confirmationCode"
          >
            <b-form-input
              id="confirmationCode"
              v-model="driverData.confirmationCode"
              readonly
            />
          </b-form-group>
        </b-col>

      </b-row>
    </b-form>

    <!-- Action Buttons -->
    <b-button
      variant="primary"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      @click="save"
    >
      Зберегти
    </b-button>
    <!-- :block="$store.getters['app/currentBreakPoint'] === 'xs'" -->
    <b-button
      variant="danger"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      @click="blockDriver"
      v-if="driverData.status != 'BLOCKED'"
    >
      Заблокувати
    </b-button>
    <b-button
      variant="danger"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      @click="unblockDriver"
      v-else-if="driverData.status == 'BLOCKED'"
    >
      Розблокувати
    </b-button>

    <small class="text-success ml-1" v-if="saved">Користувача збережено</small>
    <small class="text-danger ml-1" v-if="error">Помилка</small>
  </div>
</template>

<script>
import {
  BButton, BMedia, BAvatar, BRow, BCol, BFormGroup, BFormInput, BForm, BTable, BCard, BCardHeader, BCardTitle, BFormCheckbox,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import useDriversList from '../drivers-list/useDriversList'
import axiosIns from "@axios";
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    vSelect,
  },
  props: {
    driverData: {
      type: Object,
      required: true,
    },
  },
  methods: {
    async save() {

      const response = await axiosIns
        .put(`/business/drivers/${this.driverData.profileId}`, {
          fullName: this.driverData.fullName,
          // role: this.userData.role,
        })
        .then(() => {
          this.saved = true
          this.error = false
        })
        .catch(() => {
          this.saved = false
          this.error = true
        })
    },
    async blockDriver() {

      const response = await axiosIns
        .post(`/business/drivers/${this.driverData.profileId}/block`)
        .then(() => {
          location.reload()
        })
        .catch(() => {
          this.toast({
              component: ToastificationContent,
              props: {
                  title: 'Error fetching users list',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
              },
          })
          return false
        })
    },
    async unblockDriver() {

      const response = await axiosIns
        .post(`/business/drivers/${this.driverData.profileId}/unblock`)
        .then(() => {
          location.reload()
        })
        .catch(() => {
          this.toast({
              component: ToastificationContent,
              props: {
                  title: 'Error fetching users list',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
              },
          })
          return false
        })
    },
  },
  setup(props) {

    const toast = useToast()

    const { resolveUserBalanceVariant } = useDriversList()

    const statusOptions = [
      { label: 'Pending', value: 'pending' },
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ]

    // ? Demo Purpose => Update image on click of update
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => {
      // eslint-disable-next-line no-param-reassign
      props.driverData.avatar = base64
    })

    const saved = false
    const error = false

    return {
      resolveUserBalanceVariant,
      avatarText,
      statusOptions,

      //  ? Demo - Update Image on click of update button
      refInputEl,
      previewEl,
      inputImageRenderer,

      saved,
      error,
      toast,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
